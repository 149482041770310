exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alcohol-en-drugs-js": () => import("./../../../src/pages/alcohol-en-drugs.js" /* webpackChunkName: "component---src-pages-alcohol-en-drugs-js" */),
  "component---src-pages-alcoholpromillage-js": () => import("./../../../src/pages/alcoholpromillage.js" /* webpackChunkName: "component---src-pages-alcoholpromillage-js" */),
  "component---src-pages-apk-algemene-periodieke-keuring-js": () => import("./../../../src/pages/apk-algemene-periodieke-keuring.js" /* webpackChunkName: "component---src-pages-apk-algemene-periodieke-keuring-js" */),
  "component---src-pages-beginnend-bestuurder-js": () => import("./../../../src/pages/beginnend-bestuurder.js" /* webpackChunkName: "component---src-pages-beginnend-bestuurder-js" */),
  "component---src-pages-boete-te-hard-rijden-js": () => import("./../../../src/pages/boete-te-hard-rijden.js" /* webpackChunkName: "component---src-pages-boete-te-hard-rijden-js" */),
  "component---src-pages-boetes-winterbanden-duitsland-js": () => import("./../../../src/pages/boetes-winterbanden-duitsland.js" /* webpackChunkName: "component---src-pages-boetes-winterbanden-duitsland-js" */),
  "component---src-pages-boetes-winterbanden-oostenrijk-js": () => import("./../../../src/pages/boetes-winterbanden-oostenrijk.js" /* webpackChunkName: "component---src-pages-boetes-winterbanden-oostenrijk-js" */),
  "component---src-pages-bumperkleven-js": () => import("./../../../src/pages/bumperkleven.js" /* webpackChunkName: "component---src-pages-bumperkleven-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-door-rood-js": () => import("./../../../src/pages/door-rood.js" /* webpackChunkName: "component---src-pages-door-rood-js" */),
  "component---src-pages-het-kenteken-js": () => import("./../../../src/pages/het-kenteken.js" /* webpackChunkName: "component---src-pages-het-kenteken-js" */),
  "component---src-pages-langstudeerboete-js": () => import("./../../../src/pages/langstudeerboete.js" /* webpackChunkName: "component---src-pages-langstudeerboete-js" */),
  "component---src-pages-lasershield-of-radar-detector-js": () => import("./../../../src/pages/lasershield-of-radar-detector.js" /* webpackChunkName: "component---src-pages-lasershield-of-radar-detector-js" */),
  "component---src-pages-nieuws-js": () => import("./../../../src/pages/nieuws/[...].js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-overboetes-js": () => import("./../../../src/pages/overboetes.js" /* webpackChunkName: "component---src-pages-overboetes-js" */),
  "component---src-pages-parkeerboete-js": () => import("./../../../src/pages/parkeerboete.js" /* webpackChunkName: "component---src-pages-parkeerboete-js" */),
  "component---src-pages-rollentestbank-js": () => import("./../../../src/pages/rollentestbank.js" /* webpackChunkName: "component---src-pages-rollentestbank-js" */),
  "component---src-pages-straatracen-js": () => import("./../../../src/pages/straatracen.js" /* webpackChunkName: "component---src-pages-straatracen-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-toegestane-hoeveelheid-alcohol-js": () => import("./../../../src/pages/toegestane-hoeveelheid-alcohol.js" /* webpackChunkName: "component---src-pages-toegestane-hoeveelheid-alcohol-js" */),
  "component---src-pages-trajectcontroles-js": () => import("./../../../src/pages/trajectcontroles.js" /* webpackChunkName: "component---src-pages-trajectcontroles-js" */),
  "component---src-pages-verkeersinformatie-js": () => import("./../../../src/pages/verkeersinformatie.js" /* webpackChunkName: "component---src-pages-verkeersinformatie-js" */),
  "component---src-pages-vrachtwagens-js": () => import("./../../../src/pages/vrachtwagens.js" /* webpackChunkName: "component---src-pages-vrachtwagens-js" */),
  "component---src-pages-winterbanden-in-europa-js": () => import("./../../../src/pages/winterbanden-in-europa.js" /* webpackChunkName: "component---src-pages-winterbanden-in-europa-js" */),
  "component---src-pages-winterbanden-js": () => import("./../../../src/pages/winterbanden.js" /* webpackChunkName: "component---src-pages-winterbanden-js" */),
  "component---src-pages-zoekresultaten-js": () => import("./../../../src/pages/zoekresultaten.js" /* webpackChunkName: "component---src-pages-zoekresultaten-js" */),
  "component---src-templates-boete-template-js": () => import("./../../../src/templates/boeteTemplate.js" /* webpackChunkName: "component---src-templates-boete-template-js" */),
  "component---src-templates-boetesoverzicht-template-js": () => import("./../../../src/templates/boetesoverzichtTemplate.js" /* webpackChunkName: "component---src-templates-boetesoverzicht-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepageTemplate.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-snelheidsovertredingen-template-js": () => import("./../../../src/templates/snelheidsovertredingenTemplate.js" /* webpackChunkName: "component---src-templates-snelheidsovertredingen-template-js" */)
}

